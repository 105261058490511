<template>
  <Admin />
</template>

<script>
import Admin from '../components/Admin'

export default {
  name: 'AdminView',

  components: {
    Admin
  }
}
</script>
